import Vue from 'vue'
import VueRouter from 'vue-router'
import CategoryCallToActionsTab from '../views/categories/category-detail/CallToActionsTab';
import CategoryDetail from '../views/categories/category-detail/CategoryDetail';
import CategoryDividersTab from '../views/categories/category-detail/CategoryDividersTab';
import ChoiceHelpTab from '../views/categories/category-detail/ChoiceHelpTab';
import ShowDivider from '../views/categories/category-detail/divider/show';
import CategoryFiltersTab from '../views/categories/category-detail/FiltersTab';
import CategoryGeneralInformationTab from '../views/categories/category-detail/GeneralInformationTab';
import CategoryMetaTab from '../views/categories/category-detail/MetaTab';
import PowerpageTab from '../views/categories/category-detail/PowerpageTab';
import CategoryProductsTab from '../views/categories/category-detail/ProductsTab';
import CategorySeoContentTab from '../views/categories/category-detail/SeoContentTab';
import CategorySettingsTab from '../views/categories/category-detail/SettingsTab';
import CategoriesCreatePage from '../views/categories/create';
import CategoriesIndexPage from '../views/categories/index';
import ChoiceHelpQuestionsAnswerCreate from '../views/choice-help/answer/create';
import ChoiceHelpQuestionsAnswer from '../views/choice-help/answer/show';
import ChoiceHelpCreate from '../views/choice-help/question/create';
import ChoiceHelpIndex from '../views/choice-help/question/index';
import ChoiceHelpShow from '../views/choice-help/question/show';
import ChoiceHelpShowAnswers from '../views/choice-help/question/tabs/answers';
import ChoiceHelpShowGeneralInformation from '../views/choice-help/question/tabs/general-information';
import DiscountCodesIndex from '../views/discount-codes/index';
import DiscountCodesShow from '../views/discount-codes/show';
import FaqQuestionsIndex from '../views/faq-questions/index';
import FaqQuestionsShow from '../views/faq-questions/show';
import FilterFormView from '../views/filters/form';
import FiltersIndexPage from '../views/filters/index';
import DashboardLayout from '../views/layouts/DashboardLayout';
import NewsCreate from '../views/news/create';
import NewsIndex from '../views/news/index';
import NewsShow from '../views/news/show';
import AboutUsPageCms from '../views/pages/AboutUsPage';
import CategoryPageCms from '../views/pages/CategoryPage';
import ErrorPageCms from '../views/pages/ErrorPage';
import HomePageCms from '../views/pages/HomePage';
import PagesLayout from '../views/pages/Layout';
import ProductPageCms from '../views/pages/ProductPage';
import ReturnsAndRefundsPage from "../views/pages/ReturnsAndRefundsPage";
import ThankYouPageCms from '../views/pages/ThankYouPage';
import PowerPagesCreate from '../views/powerpages/Create';
import PowerPagesIndex from '../views/powerpages/Index';
import ProductAccessories from '../views/products/accessories';
import ProductConfigurator from '../views/products/configurator';
import ProductCreateView from '../views/products/create';
import ProductFilters from '../views/products/filters';
import ProductIndexView from '../views/products/index';
import AccessoriesTab from '../views/products/product-detail/AccessoriesTab';
import ConfiguratorQuestionsTab from '../views/products/product-detail/ConfiguratorQuestionsTab.vue';
import GeneralInformationTab from '../views/products/product-detail/GeneralInformationTab.vue';
import ImagesTab from '../views/products/product-detail/ImagesTab.vue';
import ProductMetaTab from '../views/products/product-detail/MetaTab';
import ProductPowerpageTab from '../views/products/product-detail/PowerpageTab';
import ProductDetail from '../views/products/product-detail/ProductDetail.vue';
import FiltersTab from '../views/products/product-detail/ProductFiltersTab';
import ProductSpecificationTab from '../views/products/product-detail/ProductSpecificationTab.vue';
import ProsAndConsTab from '../views/products/product-detail/ProsAndConsTab.vue';
import RelatedProductsTab from "../views/products/product-detail/RelatedProductsTab";
import RelevantProductsTab from '../views/products/product-detail/RelevantProductsTab';
import ProsAndCons from '../views/products/pros-and-cons';
import RelevantProducts from '../views/products/relevant-products';
import RelatedProducts from '../views/products/related-products';
import ProductSpecificationCategory from '../views/products/specification-categories';
import ProductSpecification from '../views/products/specifications';
import RedirectsIndex from '../views/redirects/index';
import RedirectsShow from '../views/redirects/show';
import StaticIndex from '../views/static-page-meta/index';
import StaticShow from '../views/static-page-meta/show';
import WorkerEdit from '../views/workers/edit';
import WorkerIndex from '../views/workers/index';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        component: DashboardLayout,
        redirect: '/producten',
        children: [
            {
                path: '/producten',
                name: 'products.index',
                component: ProductIndexView,
            },
            {
                path: '/producten/nieuw',
                name: 'products.create',
                component: ProductCreateView,
            },
            {
                path: '/producten/:id',
                component: ProductDetail,
                children: [
                    {
                        path: '',
                        redirect: 'algemene-gegevens',
                    },
                    {
                        path: 'algemene-gegevens',
                        name: 'products.general-information',
                        component: GeneralInformationTab,
                    },
                    {
                        path: 'configurator-vragen',
                        component: ConfiguratorQuestionsTab,
                        name: 'products.configurator',
                    },
                    {
                        path: 'afbeeldingen',
                        component: ImagesTab,
                    },
                    {
                        path: 'specificaties',
                        component: ProductSpecificationTab,
                        name: 'products.specification',
                    },
                    {
                        path: 'pros-and-cons',
                        component: ProsAndConsTab,
                        name: 'products.pros-and-cons',
                    },
                    {
                        path: 'relevante-producten',
                        component: RelevantProductsTab,
                        name: 'products.relevant-products',
                    },
                    {
                        path: 'gerelateerde-producten',
                        component: RelatedProductsTab,
                        name: 'products.related-products',
                    },
                    {
                        path: 'filters',
                        component: FiltersTab,
                        name: 'products.filters',
                    },
                    {
                        path: 'accessoires',
                        component: AccessoriesTab,
                        name: 'products.accessories',
                    },
                    {
                        path: 'meta',
                        component: ProductMetaTab,
                        name: 'products.meta',
                    },
                    {
                        path: 'powerpage',
                        component: ProductPowerpageTab,
                        name: 'products.powerpage',
                    },
                ],
            },
            {
                path: '/producten/:id/configurator-vragen/aanmaken',
                name: 'products.configurator.create',
                component: ProductConfigurator,
            },
            {
                path: '/producten/:id/configurator-vragen/:configuration',
                name: 'products.configurator.edit',
                component: ProductConfigurator,
            },
            {
                path: '/producten/:id/specificatie-categorie/aanmaken',
                name: 'products.specification-category.create',
                component: ProductSpecificationCategory,
            },
            {
                path: '/producten/:id/specificatie-categorie/:category',
                name: 'products.specification-category.edit',
                component: ProductSpecificationCategory,
            },
            {
                path: '/producten/:id/specificatie/aanmaken',
                name: 'products.specification.create',
                component: ProductSpecification,
            },
            {
                path: '/producten/:id/specificatie/:specification',
                name: 'products.specification.edit',
                component: ProductSpecification,
            },
            {
                path: '/producten/:id/pros-and-cons/aanmaken',
                name: 'products.pros-and-cons.create',
                component: ProsAndCons,
            },
            {
                path: '/producten/:id/pros-and-cons/:item',
                name: 'products.pros-and-cons.edit',
                component: ProsAndCons,
            },
            {
                path: '/producten/:id/relevante-producten/aanmaken',
                name: 'products.relevante-producten.create',
                component: RelevantProducts,
            },
            {
                path: '/producten/:id/relevante-producten/:relevantProduct',
                name: 'products.relevante-producten.edit',
                component: RelevantProducts,
            },
            {
                path: '/producten/:id/gerelateerde-producten/aanmaken',
                name: 'products.related-products.create',
                component: RelatedProducts,
            },
            {
                path: '/producten/:id/gerelateerde-producten/:relatedProductGroup',
                name: 'products.related-products.edit',
                component: RelatedProducts,
            },
            {
                path: '/producten/:id/filters/aanmaken',
                name: 'products.filters.create',
                component: ProductFilters,
            },
            {
                path: '/producten/:id/filters/:filterCategory',
                name: 'products.filters.edit',
                component: ProductFilters,
            },
            {
                path: '/producten/:id/accessoires/aanmaken',
                name: 'products.accessories.create',
                component: ProductAccessories,
            },
            {
                path: '/producten/:id/accessoires/:accessoryProduct',
                name: 'products.accessories.edit',
                component: ProductAccessories,
            },
            {
                path: '/pages',
                name: 'pages',
                redirect: '/pages/home-page',
                component: PagesLayout,
                children: [
                    {
                        path: 'home-page',
                        name: 'pages.home-page',
                        component: HomePageCms,
                    },
                    {
                        path: 'thank-you',
                        name: 'pages.thank-you',
                        component: ThankYouPageCms,
                    },
                    {
                        path: 'returns-and-refunds',
                        name: 'pages.returns-and-refunds',
                        component: ReturnsAndRefundsPage,
                    },
                    {
                        path: 'category',
                        name: 'pages.category',
                        component: CategoryPageCms,
                    },
                    {
                        path: 'product',
                        name: 'pages.product',
                        component: ProductPageCms,
                    },
                    {
                        path: 'about-us',
                        name: 'pages.about-us',
                        component: AboutUsPageCms,
                    },
                    {
                        path: '404',
                        name: 'pages.error',
                        component: ErrorPageCms,
                    },
                ],
            },
            {
                path: '/powerpage',
                name: 'powerpages',
                component: PowerPagesIndex,
            },
            {
                path: '/powerpage/create',
                name: 'powerpages.create',
                component: PowerPagesCreate,
            },
            {
                path: '/powerpage/:id',
                name: 'powerpages.show',
                component: PowerPagesCreate,
            },
            {
                path: '/filters',
                name: 'filters.index',
                component: FiltersIndexPage,
            },
            {
                path: '/filters/nieuw',
                name: 'filters.create',
                component: FilterFormView,
            },
            {
                path: '/filters/:id',
                name: 'filters.edit',
                component: FilterFormView,
            },
            {
                path: '/categorieen',
                name: 'categories.index',
                component: CategoriesIndexPage,
            },
            {
                path: '/categorieen/nieuw',
                name: 'categories.create',
                component: CategoriesCreatePage,
            },
            {
                path: '/categorieen/subcategorie/nieuw',
                name: 'categories.create-sub',
                component: CategoriesCreatePage,
            },
            {
                path: '/categorieen/:id',
                component: CategoryDetail,
                children: [
                    {
                        path: '',
                        redirect: 'algemene-gegevens',
                    },
                    {
                        path: 'algemene-gegevens',
                        name: 'categories.general-information',
                        component: CategoryGeneralInformationTab,
                    },
                    {
                        path: 'labels',
                        name: 'categories.category_dividers',
                        component: CategoryDividersTab,
                    },
                    {
                        path: 'seo-content',
                        component: CategorySeoContentTab,
                        name: 'categories.seo_content',
                    },
                    {
                        path: 'instellingen',
                        component: CategorySettingsTab,
                        name: 'categories.settings',
                    },
                    {
                        path: 'filters',
                        component: CategoryFiltersTab,
                        name: 'categories.filters',
                    },
                    {
                        path: 'producten',
                        component: CategoryProductsTab,
                        name: 'categories.products',
                    },
                    {
                        path: 'meta',
                        component: CategoryMetaTab,
                        name: 'categories.meta',
                    },
                    {
                        path: 'powerpage',
                        component: PowerpageTab,
                        name: 'categories.powerpage',
                    },
                    {
                        path: 'keuzehulp',
                        component: ChoiceHelpTab,
                        name: 'categories.choice_help',
                    },
                    {
                        path: 'call-to-actions',
                        component: CategoryCallToActionsTab,
                        name: 'categories.ctas',
                    }
                ],
            },
            {
                path: '/categorieen/:id/labels/aanmaken',
                name: 'categories.dividers.create',
                component: ShowDivider,
            },
            {
                path: '/categorieen/:id/labels/:divider',
                name: 'categories.dividers.edit',
                component: ShowDivider,
            },
            {
                path: '/kortingscodes',
                name: 'discount-codes',
                component: DiscountCodesIndex,
            },
            {
                path: '/kortingscodes/:id',
                name: 'discount-codes.show',
                component: DiscountCodesShow,
            },
            {
                path: '/kortingscodes/create',
                name: 'discount-codes.create',
                component: DiscountCodesShow,
            },
            {
                path: '/nieuws',
                name: 'news.index',
                component: NewsIndex,
            },
            {
                path: '/nieuws/aanmaken',
                name: 'news.create',
                component: NewsCreate,
            },
            {
                path: '/nieuws/:id',
                name: 'news.show',
                component: NewsShow,
            },
            {
                path: '/keuzehulpvragen',
                name: 'choice-help.index',
                component: ChoiceHelpIndex,
            },
            {
                path: '/keuzehulpvragen/nieuw',
                name: 'choice-help.create',
                component: ChoiceHelpCreate,
            },
            {
                path: '/keuzehulpvragen/:id',
                component: ChoiceHelpShow,
                children: [
                    {
                        path: '',
                        redirect: 'algemene-gegevens',
                    },
                    {
                        path: 'algemene-gegevens',
                        name: 'choice-help.general-information',
                        component: ChoiceHelpShowGeneralInformation,
                    },
                    {
                        path: 'antwoorden',
                        name: 'choice-help.answers',
                        component: ChoiceHelpShowAnswers,
                    },
                ],
            },
            {
                path: '/keuzehulpvragen/:id/antwoorden/nieuw',
                name: 'choice-help.answer.create',
                component: ChoiceHelpQuestionsAnswerCreate,
            },
            {
                path: '/keuzehulpvragen/:id/antwoorden/:answer_id',
                name: 'choice-help.answer.show',
                component: ChoiceHelpQuestionsAnswer,
            },
            {
                path: '/redirects',
                name: 'redirects.index',
                component: RedirectsIndex,
            },
            {
                path: '/redirects/:id',
                name: 'redirects.show',
                component: RedirectsShow,
            },
            {
                path: '/redirects/create',
                name: 'redirects.create',
                component: RedirectsShow,
            },
            {
                path: '/faq-questions',
                name: 'faq-questions.index',
                component: FaqQuestionsIndex,
            },
            {
                path: '/faq-questions/:id',
                name: 'faq-questions.show',
                component: FaqQuestionsShow,
            },
            {
                path: '/faq-questions/create',
                name: 'faq-questions.create',
                component: FaqQuestionsShow,
            },
            {
                path: '/static',
                name: 'static',
                component: StaticIndex,
            },
            {
                path: '/static/create',
                name: 'static.create',
                component: StaticShow,
            },
            {
                path: '/static/:id',
                name: 'static.show',
                component: StaticShow,
            },
            {
                path: '/worker',
                name: 'workers.index',
                component: WorkerIndex,
            },
            {
                path: '/worker/create',
                name: 'workers.create',
                component: WorkerEdit,
            },
            {
                path: '/worker/:id',
                name: 'workers.edit',
                component: WorkerEdit,
            },
        ],
    },
];

const router = new VueRouter({
    mode: 'history',
    base: '/',
    routes,
});

export default router;
